@mixin bp($value, $value2: null) {
    $breakpoint: (
        0: 0px,
        1: 100px,
        2: 200px,
        3: 360px,
        4: 480px,
        5: 512px,
        6: 640px,
        7: 768px,
        8: 800px,
        9: 960px,
        10: 1024px,
        11: 1152px,
        12: 1280px,
        13: 1366px,
        14: 1440px,
        15: 1536px,
        16: 1600px,
        17: 1700px,
        18: 1800px,
        19: 1920px
    );
    $baseFont: 16px;
    @if length($value) == 1 and $value2 == null {
        $minWidth: map-get($breakpoint, nth($value, 1));
        @media (min-width: $minWidth / $baseFont * 1em) {
            @content;
        }
    }
    @if length($value) == 2 and $value2 == null {
        $minWidth: map-get($breakpoint, nth($value, 1));
        $maxWidth: map-get($breakpoint, nth($value, 2)) - 1px;
        @media (min-width: $minWidth / $baseFont * 1em) and (max-width: $maxWidth / $baseFont * 1em) {
            @content;
        }
    }
    @if $value2 {
        @if length($value2) == 1 {
            $minWidth: map-get($breakpoint, nth($value, 1));
            $maxWidth: map-get($breakpoint, nth($value, 2)) - 1px;
            $minWidth2: map-get($breakpoint, nth($value2, 1));
            @media (min-width: $minWidth / $baseFont * 1em) and (max-width: $maxWidth / $baseFont * 1em), (min-width: $minWidth2 / $baseFont * 1em) {
                @content;
            }
        }
        @if length($value2) == 2 {
            $minWidth: map-get($breakpoint, nth($value, 1));
            $maxWidth: map-get($breakpoint, nth($value, 2)) - 1px;
            $minWidth2: map-get($breakpoint, nth($value2, 1));
            $maxWidth2: map-get($breakpoint, nth($value2, 2)) - 1px;
            @media (min-width: $minWidth / $baseFont * 1em) and (max-width: $maxWidth / $baseFont * 1em),
            (min-width: $minWidth2 / $baseFont * 1em) and (max-width: $maxWidth2 / $baseFont * 1em) {
                @content;
            }
        }
    }
}
