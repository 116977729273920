
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        






























.dash-header-hamburger {
  @include size(24px);
  display: grid;
  align-content: flex-start;
  cursor: pointer;
  &--open {
    @include margin(12px _ _);
  }
  @include bp(7) {
    display: none;
  }
  &__hr {
    @include size(100% 2px);
    @include margin(_ _ 3px);
    display: block;
    background: #d3dcec;
    transition: all 300ms;
    &:nth-child(1) {
      .dash-header-hamburger--open & {
        transform: rotate(45deg);
      }
    }
    &:nth-child(2) {
      .dash-header-hamburger--open & {
        display: none;
      }
    }
    &:nth-child(3) {
      .dash-header-hamburger--open & {
        @include relative(_ _ 5px);
        transform: rotate(-45deg);
      }
    }
  }
  &__text {
    @include font(500 9px "Roboto");
    color: #94a3be;
    .dash-header-hamburger--open & {
      @include margin(5px _ _);
    }
  }
}
