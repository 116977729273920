
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        





















































.dash {
  @include padding(60px _ _ _);
  @include bp(7) {
    @include padding(80px _ _ 240px);
  }
  &--compact {
    @include bp(7) {
      @include padding(80px _ _ 60px);
    }
  }
  &__main-content {
    @include padding(_ 20px 20px);
  }
}

body {
  font-weight: 400;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system, BlinkMacSystemFont;
}

/* Containers ****************** */
.container-fluid {
  padding: 0 20px;
}

.card {
  margin-bottom: 20px;
}

.card-header {
  padding: .35rem 0.85rem;
}

.card-body {
  padding: 1rem;
}

.form-edit-header {
  color: #bbb;
  font-weight: 100;
}

/* Modals ********************** */
.modal {
  overflow-y: auto;

  .modal-title {
    text-align: left;
  }
}

.modal-header {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    padding-right: 0;
    padding-left: 0;
  }

  button.close {
    margin-left: 20px;
  }
}

.modal-content {
  min-height: 200px;
}

@media (min-width: 992px) {
  .modal-lg {
    min-width: 95%;
  }
}

@media (min-width: 1500px) {
  .modal-lg {
    min-width: 1400px;

    .modal-content {
      width: 1400px;
    }
  }
  .media-modal .modal-lg,
  .media-modal.modal-lg {
    min-width: 1520px;

    .modal-content {
      width: 1520px;
    }
  }
}

.modal-center {
  text-align: center;
  padding: 0 !important;

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.modal-center:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

/* Forms *************************** */
.form-control {
  font-size: 14px;
}

.form-control::placeholder {
  color: #bbb;
}

.form-group {
  margin-bottom: 18px;
}

div.error input,
div.error textarea,
div.error iframe,
input.error,
textarea.error {
  border: 1px solid #fc4b6c;
}

div.error label,
.grey-header {
  color: #bbb;
  font-weight: 100;
}

label,
[type=checkbox] + label,
[type=radio]:checked + label,
[type=radio]:not(:checked) + label {
  font-size: 0.85rem;
}

label.error {
  color: #fc4b6c;
}

.multiselect__tags {
  border: 1px solid #ced4da;
}

/* Tables ************************* */
.table {
  thead {
    th {
      vertical-align: middle;
    }
  }
}

.table,
.table-responsive {
  th, td {
    font-size: 14px
  }
}

.table th, .table thead th {
  font-weight: 600;
}

/* DashSidebar ******************************* */
//.left-sidebar {
//  padding-top: 50px;
//}

.sidebar-nav {
  padding: 0;

  ul li a {
    padding: 8px 35px 8px 20px;
    border-radius: 0;
  }

  ul li ul {
    background: #1d232a;
    padding-left: 23px;
    padding-bottom: 10px;
  }

  ul li ul li a {
    padding: 6px 35px 6px 15px;
  }

  ul li li.router-link-active a {
    color: #ffffff;
  }

  > ul > li.active > a,
  > ul > li.active:hover > a {
    background: #fc4b6c !important;
  }

  ul li a:hover,
  > ul > li > a:hover i {
    color: #fff;
  }
}

@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    width: 260px;
    background: #fc4b6c !important;
    color: #ffffff;
    border-color: #009efb;
  }
}

.sidebar-footer a {
  width: 50%;
}

.version-info {
  padding: 0 0 5px 0;
  width: 100%;
  font-size: 13px;
}

/* Buttons **************************** */
.btn:not(.btn-sm):not(.btn-xs):not(.btn-md):not(.btn-lg) {
  padding: 7px 10px;
  font-size: 13px;
  cursor: pointer;
}

.input-group .btn:not(.btn-sm):not(.btn-xs):not(.btn-md):not(.btn-lg) {
  font-size: 1rem;
}

.btn-sm {
  padding: .25rem .4rem;
}

.btn-green {
  background: #41b883;
  color: #ffffff;
}

.btn-inverse,
.btn-skype,
.btn-green {
  transition: 0.2s ease-in;
}

.btn-inverse:hover {
  opacity: 1;
  -webkit-box-shadow: 0 14px 26px -12px rgba(29, 35, 42, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 14px 26px -12px rgba(29, 35, 42, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.btn-skype:hover {
  opacity: 1;
  -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
  box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
}

.btn-green:hover {
  opacity: 1;
  -webkit-box-shadow: 0 14px 26px -12px rgba(0, 89, 3, 0.42), 0 4px 23px 0 rgba(0, 87, 3, 0.12), 0 8px 10px -5px rgba(0, 87, 3, 0.2);
  box-shadow: 0 14px 26px -12px rgba(0, 89, 3, 0.42), 0 4px 23px 0 rgba(0, 89, 3, 0.12), 0 8px 10px -5px rgba(0, 87, 3, 0.2);
}

.btn-blue {
  padding-left: rem(25px);
  padding-right: rem(25px);
  font-family: "Roboto", sans-serif;
  font-size: rem(13px);
  font-weight: 500;
  color: #fff;
  background: #6599FE;
  border: none;
  border-radius: rem(6px);
  cursor: pointer;
  height: rem(40px);
  transition: background 100ms;
  display: flex;
  align-items: center;
  &:hover {
    background: darken(#6599FE, 10%);
  }
}

/* Dam module buttons ********************/
.dam-buttons {
  .dam-upload-btn {
    position: absolute;
    top: -60px;
    right: 93px;
    z-index: 5;
  }
}

/* Helpers *********************************/
.m-t-25 {
  margin-top: 25px;
}
