
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        

























































.dash--new-article-btn {
    padding-left: rem(15px);
    padding-right: rem(15px);
    margin-right: rem(15px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #6599FE;
    background: transparent;
    border: none;
    border-right: 1px solid #d1dbe4;
    cursor: pointer;
    height: rem(40px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    span {
      margin-left: rem(5px);
      transform: translateY(1px);
    }
    svg {
      fill: #6599FE;
    }

    @include bp(0 7) {
      span {
        display: none;
      }
    }
}

