.multiselect {
  font-family: "Roboto", sans-serif;
  font-size: rem(14px);
  font-weight: 400;
  color: #465674;
  &__placeholder,
  &__input,
  &__single {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    padding-top: 0;
    color: #465674;
  }
  &__option {
    &--highlight {
      background: #6599FE;
    }
  }
  &__tag {
    background: #6599FE;
  }
  &__tags {
    border: 1px solid #d1dbe4;
    border-radius: rem(6px);
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &__tag-icon {
    &:focus,
    &:hover {
      background: #657ca7;
    }
    &::after {
      color: #fff;
    }
  }
  &__spinner {
    &::after,
    &::before {
      border-top-color: #6599FE;
    }
  }
  &__select {
    &::before {
      content: url('/img/svg/arrow-down.svg');
      background-size: 10px 20px;
      border: none;
      top: calc(50% - 4px);
    }
  }
  &__clear {
    cursor: pointer;
    padding: 8px 0 8px 8px;
    position: absolute;
    right: 41px;
    top: 5px;

    svg {
      fill: #999;
      height: 11px;
      width: 11px;
    }
  }
}
