
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        
































































.login {
  @include size(100% 100vh);
  @include fixed(0, 100);
  @include size(100% 100vh);
  min-height: 100vh;
  overflow-y: scroll;
  background: #f3f4f8;
  display: flex;
  justify-content: center;
  @include bp(7) {
    align-items: center;
  }
  &__dialog-wr {
    @include padding(_ 15px);
    @include size(500px _);
    @include bp(0 7) {
      @include padding(50px _ _);
    }
  }
  &__form {
    @include margin(_ _ 50px);
    @include padding(30px);
    @include radius(7px);
    @include grid-gap(25px);
    box-shadow: 4px 4px 20px 0 rgba(71, 134, 255, 0.1);
    background: #fff;
    display: grid;
    @include bp(7) {
      @include padding(60px);
    }
  }
  &__form-input {
    @include font(400 16px "Roboto");
    @include padding(_ _ 5px _);
    color: rgba(0,0,0, .8);
    border: none;
    border-bottom: 1px solid #465674;
    &::placeholder {
      color: rgba(0,0,0, .4);
    }
  }
  &__form-submit {
    @include font(500 20px "Roboto");
    @include padding(10px _);
    @include radius(4px);
    color: #fff;
    background: #6599fe;
    border: none;
    cursor: pointer;
    transition: background 150ms;
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__version {
    @include font(500 15px "Roboto");
    color: #b8c0cd;
    text-align: center;
  }
}
