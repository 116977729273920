@mixin padding($raw-value: 0, $important: null) {
    $value: ();
    $shortnotation: true;
    $baseFont: 16px;
    @each $val in $raw-value {
        @if $val == _ {
            $shortnotation: false;
        }
        @if type-of($val) == number and unit($val) == px and $val >= 5 {
            $val: $val / $baseFont * 1rem;
        }
        $value: append($value, $val);
    }
    @if $shortnotation {
        padding: $value $important;
    } @else {
        @if length($value) == 2 {
            $y: nth($value, 1);
            $x: nth($value, 2);
            @if $y != _ {
                padding-top: $y $important;
                padding-bottom: $y $important;
            }
            @if $x != _ {
                padding-right: $x $important;
                padding-left: $x $important;
            }
        } @else if length($value) == 3 {
            $t: nth($value, 1);
            $x: nth($value, 2);
            $b: nth($value, 3);
            @if $t != _ {
                padding-top: $t $important;
            }
            @if $x != _ {
                padding-right: $x $important;
                padding-left: $x $important;
            }
            @if $b != _ {
                padding-bottom: $b $important;
            }
        } @else if length($value) == 4 {
            $t: nth($value, 1);
            $r: nth($value, 2);
            $b: nth($value, 3);
            $l: nth($value, 4);
            @if $t != _ {
                padding-top: $t $important;
            }
            @if $r != _ {
                padding-right: $r $important;
            }
            @if $b != _ {
                padding-bottom: $b $important;
            }
            @if $l != _ {
                padding-left: $l $important;
            }
        }
    }
}
