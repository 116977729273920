@mixin relative($raw-value: _, $z-index: null) {
    position: relative;
    $value: ();
    $baseFont: 16px;
    @each $val in $raw-value {
        @if type-of($val) == number and unit($val) == px and $val >= 5 {
            $val: $val / $baseFont * 1rem;
        }
        $value: append($value, $val);
    }
    @if length($value) == 1 {
        $value: nth($value, 1);
        @if $value != _ {
            top: $value;
            right: $value;
            bottom: $value;
            left: $value;
        }
    } @else if length($value) == 2 {
        $top-bottom: nth($value, 1);
        $right-left: nth($value, 2);
        @if $top-bottom != _ {
            top: $top-bottom;
            bottom: $top-bottom;
        }
        @if $right-left != _ {
            right: $right-left;
            left: $right-left;
        }
    } @else if length($value) == 3 {
        $top: nth($value, 1);
        $right-left: nth($value, 2);
        $bottom: nth($value, 3);
        @if $top != _ {
            top: $top;
        }
        @if $right-left != _ {
            right: $right-left;
            left: $right-left;
        }
        @if $bottom != _ {
            bottom: $bottom;
        }
    } @else if length($value) == 4 {
        $top: nth($value, 1);
        $right: nth($value, 2);
        $bottom: nth($value, 3);
        $left: nth($value, 4);
        @if $top != _ {
            top: $top;
        }
        @if $right != _ {
            right: $right;
        }
        @if $bottom != _ {
            bottom: $bottom;
        }
        @if $left != _ {
            left: $left;
        }
    }
    @if $z-index {
        z-index: $z-index;
    }
}

h2 {
    @include relative(_);
}
