@mixin grid-gap($value, $important: null) {
    $baseFont: 16px;
    @if type-of(nth($value, 1)) == number and unit(nth($value, 1)) == px and nth($value, 1) >= 5 {
        grid-row-gap: nth($value, 1) / $baseFont * 1rem $important;
    } @else if nth($value, 1) != _ {
        grid-row-gap: nth($value, 1);
    }
    @if type-of(nth($value, length($value))) == number and unit(nth($value, length($value))) == px and nth($value, length($value)) >= 5 {
        $value: nth($value, length($value)) / $baseFont * 1rem;
    }
    @if nth($value, length($value)) != _ {
        grid-column-gap: nth($value, length($value)) $important;
    }
}
