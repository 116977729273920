
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        























.login {
  &__logo {
    width: 13rem;
    height: auto;
    fill: #0C8CCD;
    position: relative;
    top: .65rem;
  }
  &__heading {
    @include font(500 34px "Roboto");
    @include margin(_ _ 30px);
    text-align: center;
    &--strong {
      font-weight: 700;
      color: #6599fe;
    }
  }
}
