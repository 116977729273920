
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        



































































































































































































































































































































































.input-group-text {
  &--error {
    border-color: #FF3455
  }
}
