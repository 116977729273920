
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        



























































































.header-route-name {
  @include font(500 22px "Roboto");
  @include margin(0);
  @include grid-gap(8px);
  color: #465674;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  .header & {
    @include bp(0 7) {
      display: none;
    }
  }
  &__icon {
    @include size(18px);
    fill: #6599fe;
  }
}
