
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        




































































































































































































































































































































.error-border {
  border-color: #fc4b6c !important;
}

.error-text {
  color: #fc4b6c !important;
}

.form-group {
  position: relative;

  #article_mainRubric {
    appearance: none;
    padding: 0 1.875rem 0 0.625rem;
    border: solid 1px #d1dbe4;
    border-radius: 0.375rem;
    color: #465674;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;

    &:focus {
      box-shadow: none;
    }
  }
  .select-area__arrow-icon {
    position: absolute;
    top: calc(50% - 6px);
    right: rem(12px);
    width: rem(11px);
    fill: #9DAAC4;
    pointer-events: none;
  }
}

button:disabled {
  background: #8e8e8e;
  border-color: #8e8e8e;
}

label {
  color: #8A96AC;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}

