@mixin font($val) {
    $baseFont: 16px;
    @if length($val) == 2 {
        font: nth($val, 1)/$baseFont * 1rem nth($val, 2)
    }
    @if length($val) == 3 {
        @if type-of(nth($val, 1)) == number and unit(nth($val, 1)) == px {
            font: nth($val, 1)/$baseFont * 1rem
            #{'/'}
            nth($val, 2)/$baseFont * 1rem nth($val, 3);
        } @else {
            font: nth($val, 1) nth($val, 2)/$baseFont * 1rem nth($val, 3);
        }
    }
    @if length($val) == 4 {
        font: nth($val, 1) nth($val, 2)/$baseFont * 1rem
        #{'/'}
        nth($val, 3)/$baseFont * 1rem nth($val, 4);
    }
    @if length($val) == 5 {
        font: nth($val, 1) nth($val, 2) nth($val, 3)/$baseFont * 1rem
        #{'/'}
        nth($val, 4)/$baseFont * 1rem nth($val, 5);
    }
}
