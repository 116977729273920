
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        







































































.header-profile {
  position: relative;
  @include bp(7) {
    @include grid-gap(12px);
    display: grid;
    grid-template-columns: repeat(3, min-content);
    align-items: center;
  }
  &__wr {
    @include grid-gap(12px);
    display: grid;
    grid-template-columns: repeat(2, min-content);
    align-items: center;
    @include bp(0 7) {
      @include absolute(30px -9px _ _, 2);
      @include padding(5px 10px);
      @include radius(3px);
      background: #6599fe;
      color: #fff;
    }
  }
  &__user-icon {
    @include size(26px);
    @include radius(50%);
    background: #d3dcec;
    font-size: 13px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__username {
    @include font(700 14px "Roboto");
    @include margin(_ _ 1px);
    color: #465674;
    @include bp(0 7) {
      color: #fff;
    }
  }
  &__email {
    @include font(400 12px "Roboto");
    @include margin(0);
    color: #8a96ac;
    @include bp(0 7) {
      color: #fff;
    }
  }
  &__logout-link {
    @include margin(_ _ _ 18px);
  }
  &__logout-icon {
    font-size: 24px;
    color: #d3dcec;
    @include bp(10) {
      transition: all 200ms;
      &:hover {
        color: #f57ba3;
      }
    }
  }
}
